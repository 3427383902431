import React, {useEffect, useState} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'
import {Link} from "react-scroll"
import Bloke from "./Bloke";
import abi from "../assets/abi.json";
import Web3 from "web3";

function Dex({shortname, contractAddr, account}) {
    const [open, setOpen] = useState(false)
    const [myNFTs, showNFTs] = useState([]);
    console.log(contractAddr + " and " + account);
    useEffect(() => {

        const _nfts = async () => {
            const web3 = new Web3(window.ethereum);
            // console.log("trying: " + abi + " and" + contractAddr);
            const contract = await new web3.eth.Contract(abi, contractAddr);
            //console.log("contract: " + contract);
            const nftBalance = await contract.methods.balanceOf(account).call();// get balance of id

            //console.log("nft balance: " + nftBalance)
            let rows = []; // empty array;
            for (let i = 0; i < nftBalance; i++) {
                try {
                    let tokenId = await contract.methods.tokenOfOwnerByIndex(account, i).call();
                    let response = await fetch(`https://gateway.ipfscdn.io/ipfs/QmNyroQ1nArcBszSq5FErcBoDxU36iuKFqqo6Ga82x4RNZ/${tokenId}.json`)
                    let data = await response.json();
                    // console.log("Got this data...");
                    rows.push(data);
                } catch (e) {
                    console.log(e.reason);
                }
            }

            showNFTs(rows);
        }
        _nfts();


    }, [account, contractAddr, open])
    return (
        <Modal style={{width: '100%', height: '100%'}}
               basic
               onClose={() => setOpen(false)}
               onOpen={() => setOpen(true)}
               open={open}
               size='small'
               trigger={<Link>{shortname}</Link>}
        >


            <Icon floated="right" color="red" circular name='window close' onClick={() => setOpen(false)}/>

            <Header icon>

                BLOKéDEX
            </Header>

            <Modal.Content style={{width: '100%', height: '100%'}}>
                <Bloke blokeData={myNFTs}></Bloke>
            </Modal.Content>

        </Modal>
    )
}

export default Dex
