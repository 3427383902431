import React from "react";
import {Container, Grid, Image} from "semantic-ui-react";
import bloke2 from "../images/poliander.png";
import bloke3 from "../images/slowtress.png";
import bloke4 from "../images/fletcill.png";
import bloke5 from "../images/blastinchan.png";
import bloke6 from "../images/suicbro.png";
import bloke7 from "../images/alkaross.png";
import bloke8 from "../images/clefoise.png";
import bloke9 from "../images/wartoom.png";


function Examples() {

    return (
        <div id='examples' >
            <Container className='container'>

            <Grid contentAlign="center" columns="4">
                <Grid.Column>
                    <Image bordered centered rounded src={bloke2}/>
                </Grid.Column>
                <Grid.Column>
                    <Image bordered centered rounded src={bloke3}/>
                </Grid.Column>

                <Grid.Column>
                    <Image bordered centered rounded src={bloke4}/>
                </Grid.Column>
                <Grid.Column>
                    <Image bordered centered rounded src={bloke5}/>
                </Grid.Column>
                <Grid.Column>
                    <Image bordered centered rounded src={bloke6}/>
                </Grid.Column>
                <Grid.Column>
                    <Image bordered centered rounded src={bloke7}/>
                </Grid.Column>
                <Grid.Column>
                    <Image bordered centered rounded src={bloke8}/>
                </Grid.Column>
                <Grid.Column>
                    <Image bordered centered rounded src={bloke9}/>
                </Grid.Column>

            </Grid>
            </Container>
        </div>
    )
}

export default Examples;