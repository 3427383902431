import React, { useState, useEffect } from 'react';
import {Statistic} from "semantic-ui-react";

function AnimatedNumberDisplay({ start, end, duration, title}) {
    const [current, setCurrent] = useState(start);

    useEffect(() => {
        let intervalId;
        const increment = (end - start) / (duration / 50);
        let next = start + increment;

        intervalId = setInterval(() => {
            setCurrent(Math.floor(next));
            if (next >= end) {
                clearInterval(intervalId);
            } else {
                next += increment;
            }
        }, 50);

        return () => clearInterval(intervalId);
    }, [start, end, duration]);

    return ( <Statistic size='small'>
            <Statistic.Value>{current}</Statistic.Value>
            <Statistic.Label>{title}</Statistic.Label>
            </Statistic>)
}

export default AnimatedNumberDisplay;
