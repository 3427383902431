import React from "react";
import {Grid, Image} from "semantic-ui-react";
import bloke1 from "../images/17.png";
import bloke2 from "../images/3447.png";
import bloke3 from "../images/5155.png";
import bloke4 from "../images/2609.png";

function About(){
    return(
        <div className='container'  id='about'>
            <Grid fluid className='container'>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <div className='orangeBox'>   <Image src={bloke1} /></div>
                    </Grid.Column>

                    <Grid.Column>
                        <div className='blueBox'>   <Image src={bloke3} /></div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='yellowBox'>   <Image src={bloke2} /></div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='purpleBox'>   <Image src={bloke4} /></div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
        )

}

export default About;