import React, {useEffect, useState} from "react";
import {Button, Card, Container, Divider, Grid, Icon, Image, Label, Modal, Segment, Statistic} from "semantic-ui-react";
import Navbar from "./Navbar";
import ava1 from "../images/1337.png";
import {Link} from "react-scroll";
import AnimatedNumberDisplay from "./numberDisplay";

function Header({myMints, maxSupply}) {
    const [open, setOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(0);





    return (
        <Container id='top'  className='container'>

            <p className="h1">BLOK<span className='main'>é</span>MON </p><Icon siz="tiny" name="trademark"/>
            <Divider inverted></Divider>


            <Grid inverted>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <AnimatedNumberDisplay start={0} end={12216} duration={2500}
                                               title="Blokémon"></AnimatedNumberDisplay>
                    </Grid.Column>
                    <Grid.Column>
                        <AnimatedNumberDisplay start={0} end={myMints} duration={2500}
                                               title="Your Mints"></AnimatedNumberDisplay>
                    </Grid.Column>
                    <Grid.Column>
                        <AnimatedNumberDisplay start={0} end={403203} duration={2500}
                                               title="Variations"></AnimatedNumberDisplay>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider>

            </Divider>
            <p> Blokémon is an NFT project where 7,100 blokés have been unleashed into the world. A world where
                users can collect & trade NFTs and then use them to battle!
                Play through an RPG blockchain based game to mint & accumulate new BLOK<span
                    className='main'>é</span>MON to your wallet. BLOK<span className='main'>é</span>MON have unique
                attributes and moves making battling with others exciting
                and immersive. Explore this new world to discover and encounter BLOK<span className='main'>é</span>MON of all varieties. Battling and
                defeating Blokés in the wild will give you the option to mint them to your wallet and add them to your
                arsenal.</p>

            <Label circular>Collect, Battle, Explore.</Label>
        </Container>
    )
}

export default Header;