import React from "react";
import {Container, Divider, Grid, Header, Icon, Image, Label, Message, Segment} from "semantic-ui-react";
import bloke1 from "../images/bulbarode.png";

import normalIcon from "../images/normal.png";
import nameIcon from "../images/mname.png";
import starsIcon from "../images/stars.png";
import statsDisplay from "../images/bulbarode_stats.png";
import numberIcon from "../images/num_rating.png"
import move1 from "../images/move1.png";
import move2 from "../images/move2.png";
import def1 from "../images/def1.png";
import def2 from "../images/def2.png";

function Detail({shortname}) {
    return (
        <div className='background'>
            <Container id='detail'>
                <Header as='h2' icon textAlign='center'>
                    <Icon name='info' circular/>
                    <Header.Content>About</Header.Content>
                </Header>
                <Divider/>
                <Image src={nameIcon} centered></Image>
                <p align="left">Blokémon are uniquely created mash-ups of a well known game, Pokémon. Each
                    and
                    every Blokémon has
                    randomly generated characteristics, moves, and stats. Bulbarode is a combination of
                    Bulbasaur
                    and
                    Electrode. </p>
                <Grid contentAlign="center" columns="3">
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Image centered src={normalIcon}/>
                                <p align="left"> Most Blokés are normal, but some were not created so
                                    equally!
                                    Blokémon can be fierce, shiny, rare, unique, rugged, feeble, weak, nimble, and many
                                    more!
                                </p>
                            </Segment>
                            <Segment>
                                <Image src={starsIcon} centered></Image> <p align="left">Blokés have six main stats that
                                contribute to the
                                star rating: HP, Attack, Special Attack, Defense, Special Defence and Speed</p>
                            </Segment>
                            <Segment>
                                <Image src={numberIcon} centered></Image>
                                <p align="left">All stats range from 5-105. The star rating, and number rating are a sum
                                    of all the statistical categories added up. The higher your Blokémons stats, the
                                    more stars they will have! </p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column textAlign="left">
                            <Image bordered rounded centered src={bloke1}/>
                            <Segment textAlign="center">
                                <Image rounded inline centered src={move1}/>
                                <Image rounded inline centered src={move2}/>
                                <Image rounded inline centered src={def1}/>
                                <Image rounded inline centered src={def2}/>
                                <p align="left">Blokés have 2 attack moves & 2 defense moves that correlate with their
                                    stat ratings.</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>

                            <Segment>
                                <Image src={statsDisplay} centered></Image>
                                <p align="left">All stats range from 5-105. The star rating, and number rating are a sum
                                    of all the statistical categories added up. The higher your Blokémons stats, the
                                    more stars they will have! </p>
                            </Segment>


                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            <Message compact content="Click your wallet address  above to see your Blokémon!"></Message>
            </Container>
        </div>
    )

}

export default Detail;
/*        <Image bordered src={statsDisplay}/>


                    <Divider/>

                    <Divider/>


 */