import React, {useEffect} from "react";
import {useState} from "react";

import image1 from "../images/1337.png";
import {ethers} from "ethers";
import {
    Button,
    Card,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    Input,
    Label,
    Message, Modal,
    Popup
} from "semantic-ui-react";
import {Link} from "react-scroll";
import abi from "../assets/abi.json";


function Mint({Addr, mints}) {
    const [open, setOpen] = useState(false)
    const [mintAmount, setMintAmount] = useState(1);
    const [error, setError] = useState(false);


    // main mint functionality
    async function handleMint() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            const contract = new ethers.Contract(
                Addr,
                abi,
                signer
            );
            // generate an array of random IDs based on the mint amount.
            const arr = [];
            for (let i = 0; i < mintAmount; i++) {
                let nftId = Math.round(Math.random() * (12216 - 1) + 1);
                try {
                    const ownerOfResponse = await contract.ownerOf(nftId);

                    if (typeof ownerOfResponse === "string") {
                        i--;
                    }
                } catch (e) {
                    // we got the err meaning it does not exist add to arr

                    if (!arr.includes(nftId)) {
                        arr.push(nftId);
                    } else {
                        i--;
                    }
                    console.log(e.error);
                    //setError(e.error);
                }

            }

            try {
                const response = await contract.mint(arr, {
                    value: ethers.utils.parseEther((arr.length).toString())
                });
                console.log("Response: ", response)
            } catch (err) {
                setError(err['reason']);

                console.log(err['reason']);
            }

        }
    }


    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handleIncrement = () => {
        if (mintAmount >= 25) return;
        setMintAmount(mintAmount + 1);
    };


    return (
        <Modal style={{width: '100%', height: '100%', justifyContent: 'center'}}
               basic
               onClose={() => setOpen(false)}
               onOpen={() => setOpen(true)}
               open={open}
               size='small'
               trigger={<Link>Mint</Link>}
        >


            <Icon floated="right" color="red" circular name='window close' onClick={() => setOpen(false)}/>

            <Header icon>
                <h1 className='h1' style={{color: 'white'}}>Mint</h1>
            </Header>

            <Modal.Content style={{textAlign: 'center'}}>
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Message >
                                <Message.Header>Blockchain</Message.Header>
                                <p>Polygon</p>
                            </Message>
                        </Grid.Column>
                        <Grid.Column>
                            <Message >
                                <Message.Header>Mint Price</Message.Header>
                                <p>1 Matic</p>
                            </Message>
                        </Grid.Column>
                        <Grid.Column>
                            <Message >
                                <Message.Header>Max Mints <Popup
                                    content='Only 25 Blokés are allowed to be minted at this time'
                                    on='click'

                                    pinned
                                    trigger={<Icon style={{cursor: 'pointer'}} name='question circle'
                                                   size="small"></Icon>}
                                /></Message.Header>
                                <p>25</p>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>

                <Button  color='red' style={{display: 'inline-block'}}
                        onClick={handleDecrement}>-</Button>
                <Input label={{icon: 'asterisk'}}
                       labelPosition='left corner'
                       placeholder='Search...' style={{height: '35px', width: '120px', borderRadius: '5px'}} readOnly
                       type="number" value={mintAmount}/>
                <Button  color='green' style={{display: 'inline-block'}}
                        onClick={handleIncrement}>+</Button>
                <Button onClick={handleMint}>Mint! </Button>


                {<Message  negative>

                    {error}

                </Message> && error}

            </Modal.Content>

        </Modal>

    )
}

export default Mint;
/*
<Container id='mint' className='container' text>

        </Container>
 */