import React, { useState } from 'react';
import {Container, Form, Input, Button, Message} from 'semantic-ui-react';

const Footer = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Perform the email subscription action
        console.log(`Subscribed with email: ${email}`);
    };

    return (
        <Container  className='container' >

            <p>Sign up to receive updates on development process</p>
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <Input
                        size="small"
                        type="email"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={e => setEmail(e.target.value)}

                    />

                </Form.Field>

            </Form>
        </Container>
    );
};

export default Footer;
