import React, {useEffect} from "react";
import {Link} from "react-scroll"
import icon from "../images/17.png"
import {useState} from "react";
import {Image} from "semantic-ui-react";
import Dex from "./Dex";
import Mint from "./Mint";

function Navbar({account, contract, shortname, myMints, networkId}) {
    const [activeLink, setActiveLink] = useState(1); // initial active link
    const [showMintComponent, setShowMintComponent] = useState(false);


    useEffect(() =>{
        if(networkId === 137){
            setShowMintComponent(true);
        }
    }, [networkId])

    console.log(networkId);
    return (
        <nav>

            <Link to='top' className='logo'>
                <Image src={icon} size={"tiny"} floated="right"></Image>
            </Link>

            <ul className='menu'>
                <li>
                    <Link to='main' smooth={true} onClick={() => setActiveLink(1)}
                          className={`${activeLink === 1 ? 'active' : ''}`} duration={1000}>Home</Link>
                </li>
                <li>
                    <Link to='about' smooth={true} onClick={() => setActiveLink(2)}
                          className={`${activeLink === 2 ? 'active' : ''}`} duration={1000}>About</Link>
                </li>
                <li>
                    <Link to='roadmap' smooth={true} onClick={() => setActiveLink(3)}
                          className={`${activeLink === 3 ? 'active' : ''}`} duration={1000}>Roadmap</Link>
                </li>
                <li>
                    {showMintComponent &&  <Mint Addr={contract}/>
                    }

                </li>
                <li>
                    <Dex contractAddr={contract} account={account} shortname={shortname}></Dex>
                </li>
            </ul>
        </nav>


    )
}

export default Navbar;

/*


            <ul className='menu'>
                <li></li>
                <li><Link to='info' smooth={true} duration={1000}>Info</Link></li>
                <li><Link to='details' smooth={true} duration={1000}>About</Link></li>
                <li><Link to='mint' smooth={true} duration={1000}>Mint</Link></li>
            <

       */