import React, {useEffect, useState} from 'react'
import {Card, Label, Rating, Progress, Grid, Image, Dimmer, Loader} from "semantic-ui-react";


const Bloke = ({blokeData}) => {
    const [isVisible, setIsVisible] = useState(false);
    const ids = Object.keys(blokeData);

    return (<div className="scrolling-content">
            <div className="ui grid">

                {ids.map(id => {
                    let image = blokeData[id].image;
                    console.log(image);

                        return (

                            <div className="three wide column">
                                <div className="ui card">
                                    <div className="extra content">
                                        <span>{blokeData[id].attributes[0]['value'].toUpperCase()}</span>


                                        <strong style={{float: 'right'}}>{blokeData[id].name.toUpperCase()}</strong>


                                    </div>
                                    <div className="image">
                                        <Image
                                            src={image} alt="BlokeImg"
                                            width={528}
                                            height={396}/>
                                    </div>
                                    <div className="content">
                                        <div className="extra content">
                                            <Rating

                                                icon="star"
                                                defaultRating={Math.floor(blokeData[id].attributes[10]['value'] / 100)}
                                                maxRating={4}
                                                style={{cursor: 'pointer', width: 100}}
                                                disabled/>

                                            <Label style={{float: 'right'}}
                                                   onClick={() => setIsVisible(!isVisible)}>{blokeData[id].attributes[10]['value']}</Label>
                                            <div style={{color: 'black', clear: 'both'}}>

                                                {isVisible && <>
                                                    <Grid>
                                                        <Grid.Row columns={4}>
                                                            <Grid.Column>
                                                                <Label size='tiny'
                                                                       color='green'>{blokeData[id].attributes[3]['value']}
                                                                </Label>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Label size='tiny'
                                                                       color='blue'>{blokeData[id].attributes[4]['value']}
                                                                </Label>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Label size='tiny' color='black'>
                                                                    {blokeData[id].attributes[5]['value']}
                                                                </Label>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Label size='tiny' color='purple'>
                                                                    {blokeData[id].attributes[11]['value']}
                                                                </Label>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>


                                                    <Progress percent={blokeData[id].attributes[1]['value']}
                                                              value={blokeData[id].attributes[1]['value']} size='tiny'
                                                              total="105"
                                                              color='red'>
                                                        <i className="heart icon red  "/> {blokeData[id].attributes[1]['value']}
                                                    </Progress>
                                                    <Progress percent={blokeData[id].attributes[2]['value']} size='tiny'
                                                              total="105"
                                                              color='green'>
                                                        <i className="crosshairs icon green  "/>{blokeData[id].attributes[2]['value']}
                                                    </Progress>
                                                    <Progress percent={blokeData[id].attributes[6]['value']} size='tiny'
                                                              total="105"
                                                              color='black'>
                                                        <i className="shield icon black "/> {blokeData[id].attributes[6]['value']}
                                                    </Progress>
                                                    <Progress percent={blokeData[id].attributes[7]['value']} size='tiny'
                                                              total="105"
                                                              color='yellow'>
                                                        <i className="lightning icon yellow "/> {blokeData[id].attributes[7]['value']}
                                                    </Progress>
                                                    <Progress percent={blokeData[id].attributes[8]['value']} size='tiny'
                                                              total="105"
                                                              color='purple'>
                                                        <i className="shield alternate icon purple"></i> {blokeData[id].attributes[8]['value']}
                                                    </Progress>
                                                    <Progress percent={blokeData[id].attributes[8]['value']} size='tiny'
                                                              total="105"
                                                              color='blue'>
                                                        <i className="plus circle icon blue"></i> {blokeData[id].attributes[8]['value']}
                                                    </Progress>

                                                </>}

                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )

}

export default Bloke
/* */