import React from "react";
import {Container, Divider, Grid, Header, Icon} from "semantic-ui-react";


function Roadmap() {
    return (
        <div className="background">
            <Container id='roadmap' className='container'>
                <Header as='h2' icon textAlign='center'>
                    <Icon name='sitemap' circular/>
                    <Header.Content>Roadmap</Header.Content>
                </Header>
                <Grid>
                    <Grid.Column width={4}>
                        <p className="roadmap">01</p>

                    </Grid.Column>
                    <Grid.Column style={{marginTop: '6%'}} width={9}>
                        <p className="roadmapText">Adoption <Icon name="add" size="large"></Icon></p>
                        <p>The first step in getting a full-blown trading and exploring game working with your NFTs is
                            to gain
                            traction through adoption. Once enough Blokés have been deployed, the value and incentive to
                            collect them will be.</p>

                    </Grid.Column>

                </Grid>
                <Divider/>
                <Grid>
                    <Grid.Column width={9}>
                        <p style={{marginTop: '10%'}}>
                            <p className="roadmapText">Development <Icon name="code" size="large"></Icon></p>
                            During this time, both a trading card platform and top down view style game are being
                            developed. The trading platform will let you offer and make trades of Blokémon NFTS
                            that other users are willing to trade. A top down style game is also in development where
                            you can explore the Blokéland, battle randomly spawning Blokés and mint them to your wallet,
                            if you win of course.
                        </p>

                    </Grid.Column>
                    <Grid.Column width={4}>
                        <p className="roadmap">02</p>
                    </Grid.Column>
                </Grid>
                <Divider/>
                <Grid>
                    <Grid.Column width={4}>
                        <p className="roadmap">03</p>

                    </Grid.Column>
                    <Grid.Column style={{marginTop: '6%'}} width={9}>
                        <p className="roadmapText">Airdrop <Icon name="paper plane" size="large"></Icon></p>
                        <p>As a token of appreciation, early adopters of Blokémon will have additional Blokés or BKMN
                            Tokens airdropped to their wallet. Tokens can be used to buy additional Blokémon
                            or access the Alpha and Beta testing of the game!</p>

                    </Grid.Column>

                </Grid>
                <Divider/>
                <Grid>

                    <Grid.Column style={{marginTop: '6%'}} width={9}>
                        <p className="roadmapText">Battle! <Icon name="play" size="large"></Icon></p>
                        <p>
                            After Alpha & Beta testing is complete, the world of Blokémon will be unleashed on the
                            world! Subscribe to the newsletter to receive updates on development and give your fan
                            feedback
                            on various questions that come up during the building process!
                        </p>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <p className="roadmap">04</p>

                    </Grid.Column>
                </Grid>
            </Container>
        </div>
    )

}

export default Roadmap;
/*
 <h4>Blokés are unique, one of a kind NFTs that contain unique stats, moves and ratings</h4>

            <Image src={Stats}/>

            <h4 align="left"> <Label style={{float:'left', marginRight:'5px'}} color='green' size='large'>Stats</Label> Blokés have 5 main attributes: HP, Attack, Defense, Special Attack, Special Defense, and Speed. These attributes are randomly generated with the lowest value being 5 and the highest being 105!</h4>
            <h4 align="left"> <Label style={{float:'left', marginRight:'5px'}} color='blue' size='large'>Moves</Label> Blokés have two attack moves & one defense move. Some move are basic, while other moves are <i className='main'>super</i>charged! Attack moves are associated with the ATK rating of the Bloké. </h4>
            <h4 align="left"> <Label style={{float:'left', marginRight:'5px'}} color='yellow' size='large'>Rating</Label> Blokés have a star rating based on all stats combined. The stronger the Bloké is, the more stars it will have! </h4>

 */