import './App.css';
import React, {useEffect, useState} from "react";
import Header from "./components/Header";
import About from "./components/About";
import abi from "./assets/abi.json";
import Web3 from "web3";
import 'semantic-ui-css/semantic.min.css'
import Detail from "./components/Detail";
import Navbar from "./components/Navbar";
import Roadmap from "./components/Roadmap";
import Examples from "./components/Examples";
import Footer from "./components/Footer";
import {Icon, Message} from "semantic-ui-react";


function App() {
    // main contract address for interaction
    const contractAddress = "0x1417735cFf2d65e70757630E4617654247fC23fF";
    const [account, setAccounts] = useState();
    const [shortName, setAccountShort] = useState('');
    const [maxSupply, setMaxSupply] = useState(0);
    const [mints, setMints] = useState(0);
    const [networkMessage, setNetworkMessage] = useState("");
    const [networkId, setNetworkId] = useState(0);
    const [previousNetworkId, setPreviousNetworkId] = useState();

    useEffect(() => {
        const web3 = new Web3(window.ethereum);
        const setContractData = async () => {

            if (window.ethereum) {
                const account = await window.ethereum.request({
                    method: "eth_requestAccounts"
                });
                setAccounts(account[0]);
                setAccountShort(account[0].substring(0, 4) + "..." + account[0].substring(account[0].length - 4))
            }
            // access contract

            const networkId = await web3.eth.net.getId();
            setNetworkId(networkId);
            if (networkId !== 137) {
                setNetworkMessage("Oops, looks like you're not on the Polygon Mainnet! Switch to Polygon Mainnet in order to Mint Blokés!")
            }else{
                // it is 137 => main net so let them go
                const contract = await new web3.eth.Contract(abi, contractAddress);
                // set max supply
                const _maxSupply = await contract.methods.maxSupply().call();
                setMaxSupply(_maxSupply)
                // set minted to wallet amount
                const _mints = await contract.methods.mintedWallets(account).call();
                setMints(_mints);
            }
        } // end of set Contract

        const checkAndReload = async () => {
            const networkId = await web3.eth.net.getId();
            if (previousNetworkId && previousNetworkId !== networkId) {
                window.location.reload();
            }
            setPreviousNetworkId(networkId);
        };


        setContractData().then(r => console.log("success"))
        const intervalId = setInterval(checkAndReload, 1000);
        return () => clearInterval(intervalId);

    }, [account])

    return (
        <div>
            {networkMessage &&
                <Message  negative>
                    <Icon name='exclamation'/>
                    {networkMessage}

                </Message>
            }
            <Navbar networkId={networkId} shortname={shortName} contract={contractAddress} myMints={mints} account={account}/>
            <Header myMints={mints} maxSupply={maxSupply}></Header>
            <About/>
            <Detail shortname={shortName}/>
            <Examples/>
            <Roadmap/>
            <Footer/>
        </div>
    );
}

export default App;
